<template>
  <div class="row mt-5 section">
    <EntityCard
      v-for="exh in exhibitions"
      :key="`exhibition-${exh.id}`"
      class="col-12 col-md-6 col-lg-4"
      :data="exh"
      data-aos="fade-up"
      type="exhibitions"
    />
  </div>
</template>

<script>
import EntityCard from '@/components/partials/EntityCard';

export default {
  name: 'ArtSpaceExhibitions',
  components: {
    EntityCard,
  },
  props: {
    exhibitions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  min-height: 350px;

  @media (min-width: 768px) {
    min-height: 500px;
  }
}
</style>
